import { Flex, Typography } from "antd";
import { LineOutlined } from "@ant-design/icons";
import { axisClasses, LineChart, lineElementClasses } from "@mui/x-charts";

const { Text } = Typography;

export const ElasticityChart = (props) => {
  const { data, func, size = { width: 900, height: 500 } } = props;

  // Safely handle undefined or null data
  const keys = data && data.keys ? data.keys : [];
  const values = data && data.values ? data.values : [];

  // Generate elasticity data
  const elasticityData = keys.map((price) => func(price));

  return (
    <Flex vertical align={"center"}>
      <Flex gap={"middle"}>
        <Flex gap={"small"}>
          <LineOutlined style={{ color: "#8a2be2" }} />
          <Text type={"secondary"}>Тренд эластичности</Text>
        </Flex>
        <Flex gap={"small"}>
          <LineOutlined style={{ color: "#000000" }} />
          <Text type={"secondary"}>Фактическая эластичность</Text>
        </Flex>
      </Flex>
      <LineChart
        xAxis={[
          {
            data: keys,
            label: "Цены (в рублях)",
          },
        ]}
        sx={{
          [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translateX(-10px)",
          },
          // Make the line dashed for the "Цены" series
          [`.MuiLineElement-series-0`]: {
            strokeDasharray: "5 5", // Dashed line style
          },
        }}
        yAxis={[
          {
            label: "Дневные продажи (шт)",
          },
        ]}
        grid={{
          vertical: true,
          horizontal: true,
        }}
        series={[
          {
            data: values,
            label: "Фактическая эластичность",
            showMark: true, // Show dots (marks)
            curve: "linear", // Keep a linear curve
            color: "#00000020"
          },
          {
            data: elasticityData,
            label: "Тренд эластичности",
            showMark: false, // Show only line, no dots (marks)
            curve: "linear", // Default curve to show line
            color: "#8a2be2"
          },
        ]}
        slotProps={{
          legend: {
            hidden: true,
          },
          loadingOverlay: {
            message: "Данные прогружаются..",
          },
          noDataOverlay: {
            message: "Недостаточно данных для отображения графика.",
          },
        }}
        width={size.width}
        height={size.height}
      />
    </Flex>
  );
};
