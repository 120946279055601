import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App/App';
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider, theme} from "antd";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ConfigProvider theme={{
                "token": {
                    "colorPrimary": "#07da16",
                    "colorInfo": "#07da16"
                },
                "components": {
                    "Layout": {
                        "algorithm": true,
                        "headerBg": "rgb(3, 147, 49)"
                    },
                    "Menu": {
                        "darkItemBg": "rgb(3, 147, 49)"
                    }
                }
                // "algorithm": theme.darkAlgorithm
            }}>
                <App/>
            </ConfigProvider>
        </BrowserRouter>
    </React.StrictMode>
);

