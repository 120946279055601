import {Button, Card, Flex, Modal, Space, Statistic, Table, Typography, DatePicker, Select, Input} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {LineChart} from "@mui/x-charts";
import {GoodChart} from "../goodcatalog/chartmodal";
import {ElasticityChart} from "./elasticitychart";
import {useEffect, useState} from "react";

const {Title, Text} = Typography;
const {RangePicker} = DatePicker;


const analyticsF = (x, a, b) => {
    return a * x + b;
}

const analyticsG = (x, a, b, selfCost) => {
    return (x - selfCost) * analyticsF(x, a, b);
}

const PriceEffect = ({value}) => {
    if (value > 0) {
        return (
            <Text style={{color: "green"}}
                  strong>+{value} руб.</Text>
        );
    } else if (value < 0) {
        return (
            <Text style={{color: "red"}}
                  strong>-{value} руб.</Text>
        );
    } else {
        return (
            <Text strong>{value} руб.</Text>
        );
    }

}

const ChangePriceModal = (props) => {
    const {isModalOpen, setIsModalOpen, good} = props;


    const [calcValue, setCalcValue] = useState("");
    const [actualCalcValue, setActualCalcValue] = useState(null);

    useEffect(() => {
        if (good && good.analyticsData && actualCalcValue === null) {
            setActualCalcValue(good.analyticsData.price);
            setCalcValue(good.analyticsData.price);
        }
    }, [good, actualCalcValue]);

    const analyticsData = (good && good.analyticsData) ? good.analyticsData : {};


    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Товар',
            dataIndex: 'good',
            key: 'good',
        },
        {
            title: 'Наша цена',
            dataIndex: 'ourPrice',
            key: 'ourPrice',
        },
        {
            title: 'Цена конкурента на рынке',
            dataIndex: 'competitorPrice',
            key: 'competitorPrice',
        },
        {
            title: 'Изменение цены за последние 24 часа',
            dataIndex: 'priceDiff24',
            key: 'priceDiff24',
            render: (diff, record) => (
                <Space size="middle">
                    {diff.type === "up" ?
                        <Statistic
                            value={diff.value}
                            valueStyle={{
                                color: '#3f8600',
                                fontSize: '12pt'
                            }}
                            prefix={<ArrowUpOutlined/>}
                            suffix="%"
                        /> : <Statistic
                            value={diff.value}
                            valueStyle={{
                                color: '#cf1322',
                                fontSize: '12pt'
                            }}
                            prefix={<ArrowDownOutlined/>}
                            suffix="%"
                        />
                    }
                </Space>
            ),
        },
    ];

    // const data = [
    //     {
    //         key: '1',
    //         id: '1',
    //         good: 'Гель для стирки Концентрат "Ночная орхидея"',
    //         ourPrice: 160,
    //         competitorPrice: 155,
    //         priceDiff24: {value: 5, type: 'down'},
    //     },
    //     {
    //         key: '2',
    //         id: '2',
    //         good: 'Гель для стирки Концентрат "Ночная орхидея"',
    //         ourPrice: 160,
    //         competitorPrice: 145,
    //         priceDiff24: {value: 7, type: 'down'},
    //     },
    //     {
    //         key: '3',
    //         id: '3',
    //         good: 'Гель для стирки Концентрат "Ночная орхидея"',
    //         ourPrice: 160,
    //         competitorPrice: 150,
    //         priceDiff24: {value: 10, type: 'down'},
    //     },
    // ];

    const data = (good && good.competitors) ? good.competitors.map((competitor) => ({
        key: competitor.id,
        id: competitor.id,
        good: competitor.name,
        ourPrice: good.actualPrice,
        competitorPrice: competitor.price,
        priceDiff24: competitor.priceDiff24
    })) : [];


    return (
        <Modal title={"Топ конкурентов изменивших цену"}
               open={isModalOpen} onCancel={() => setIsModalOpen(false)}
               onOk={() => setIsModalOpen(false)}
               width={"86%"}
               cancelButtonProps={{style: {display: 'none'}}}
               okButtonProps={{style: {display: 'none'}}}
               okText={"OK"}
        >
            <Flex vertical gap={"middle"}>
                <Table columns={columns} dataSource={data} pagination={false}/>
                <Flex gap={"middle"} justify={"space-evenly"}>
                    <Card>
                        <Title level={3}>График изменения цен за две недели</Title>
                        {good && good.chartData &&
                            <GoodChart series={good.chartData} size={{width: 500, height: 400}}/>}
                    </Card>
                    <Card>
                        <Title level={3}>Прогнозный эффект от изменения цены</Title>
                        <Flex vertical gap={"large"}>
                            <ElasticityChart data={analyticsData.elasticityTrend < 0 ? analyticsData : {}}
                                             func={(x) => analyticsF(x, analyticsData.elasticityTrend,
                                                 analyticsData.elasticityOffset)} size={{width: 500, height: 400}}/>
                            <Flex vertical gap={"middle"}>
                                <Text>Цена <Text strong>{actualCalcValue} руб.</Text></Text>
                                {/*<Text>Эластичность <Text strong>{analyticsData.elasticityTrend}</Text></Text>*/}
                                <Text>Дневной объем продаж <Text strong>{analyticsF(
                                    actualCalcValue,
                                    analyticsData.elasticityTrend,
                                    analyticsData.elasticityOffset
                                ).toFixed()} шт.</Text></Text>
                                {good && good.actualPrice &&
                                    <Text>Прогнозируемый дневной эффект на продажи* <PriceEffect value={
                                        analyticsG(
                                            actualCalcValue,
                                            analyticsData.elasticityTrend,
                                            analyticsData.elasticityOffset,
                                            analyticsData.selfPrice
                                        ).toFixed() - analyticsG(
                                            good.actualPrice,
                                            analyticsData.elasticityTrend,
                                            analyticsData.elasticityOffset,
                                            analyticsData.selfPrice
                                        ).toFixed()}/> </Text>
                                }
                                <Text type={"secondary"}>* - Эффект рассчитывается продаж по актуальной цене</Text>
                            </Flex>
                            <Space.Compact>
                                <Input onChange={(e) => setCalcValue(e.target.value)}
                                       defaultValue={analyticsData.price}/>
                                <Button onClick={() => {
                                    setActualCalcValue(parseFloat(calcValue));
                                }} type={"primary"}>Применить</Button>
                            </Space.Compact>
                            <Button type={"dashed"}>
                                Выставить цену на маркетплейсах
                            </Button>
                        </Flex>
                    </Card>
                </Flex>
            </Flex>
        </Modal>

    );
}

export default ChangePriceModal;