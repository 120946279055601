import Login from "../../components/login/login";
import {Flex, Typography} from "antd";
import Charts from "../../components/charts/charts";

const {Title} = Typography;

const ChartsPage = () => {

    return (
        <Flex vertical align={"center"} justify={"flex-start"} style={{
            minHeight: '85vh',
            width: '100%',
        }} gap={"large"}>
            <Title level={1}>Графики</Title>
            <Charts/>
        </Flex>
    );
}

export default ChartsPage;