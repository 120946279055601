import {Col, Row, Flex, Image, Typography, Avatar, Divider, Skeleton, Input, Button} from "antd";
import {CloudUploadOutlined, RightSquareOutlined, SendOutlined, UserOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import {postAiQuestion} from "../../hooks/api";
// import {postAiQuestion, useAiQuestion} from "../../api/api";

const {Text} = Typography;
const {TextArea} = Input;
const ChatInput = (props) => {
    const {isWaiting, chatMessages, setChatMessages} = props;
    const [questionText, setQuestionText] = useState("");
    const [answerLoading, setAnswerLoading] = useState(false);
    const [questionAnswer, setQuestionAnswer] = useState(null);

    useEffect(() => {
        if (questionAnswer) {
            console.log(questionAnswer);
            setChatMessages([
                ...chatMessages.slice(0, -1),
                {isAnswer: true, text: questionAnswer.answer, loading: false}
            ]);
            setQuestionAnswer(null);
            setAnswerLoading(false);
        }
    }, [questionAnswer])

    const onSend = () => {
        setChatMessages([
            ...chatMessages,
            {isAnswer: false, text: questionText, loading: false},
            {isAnswer: true, text: "", loading: true}
        ]);
        postAiQuestion(questionText, setQuestionAnswer);
        // setQuestionText("");
        setAnswerLoading(true);
    };

    return (
        // <>
        <Flex gap={"middle"} justify={"flex-start"} align={"center"}
              style={{marginTop: "5%", bottom: "3vh", width: "90%"}}>
            <TextArea
                loading={answerLoading}
                onChange={(e) => setQuestionText(e.target.value)}
                style={{
                    // maxWidth: "100vh",
                    width: "100%"
                }}
                onPressEnter={onSend}
                placeholder="Введите ваш вопрос.."
                showCount
                maxLength={1500}
                autoSize={{
                    minRows: 3,
                    maxRows: 3,
                }}

            />
            <Button loading={answerLoading} shape={"circle"} icon={<SendOutlined/>} size={"large"}
                    onClick={onSend}></Button>
        </Flex>
        // </>
    );
}
export default ChatInput;
