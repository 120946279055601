import {Button, ConfigProvider, Flex, Pagination, Space, Statistic, Table, Tooltip, Typography} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, EditOutlined, LineChartOutlined, PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import ImportGoodsModal from "./importgoodsmodal";
import {postDeleteGoods, postDeleteUsers, postUpdatePrices, useGoods} from "../../hooks/api";
import {IoIosRefresh} from "react-icons/io";
import AddGoodForm from "./addgoodmodal";
import DiffPrice from "../statistics/diffprice";
import ChartModal from "./chartmodal";
import AddCompetitorForm from "./addcompetitormodal";
import {apiURL} from "../../config";

const {Text} = Typography;

const DeleteGoodButton = (props) => {
    const {count, goodIds, deleteSelectedRows, responseData, setResponseData} = props;
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        if (isButtonLoading && responseData) {
            setIsButtonLoading(false);
            deleteSelectedRows();
        }
    }, [responseData, setResponseData]);

    const onButtonClick = () => {
        console.log("goods to delete: ", goodIds);
        setIsButtonLoading(true);
        postDeleteGoods(goodIds, setResponseData);
        deleteSelectedRows();
    }

    if (count === 0) {
        return (<></>);
    } else if (count === 1) {
        return (
            <Button onClick={onButtonClick} loading={isButtonLoading} danger>Удалить товар</Button>
        );
    } else {
        return (
            <Button onClick={onButtonClick} loading={isButtonLoading} danger>Удалить товары: {count}</Button>
        );
    }
}

const UpdateGoodPriceButton = (props) => {
    const {count, selectedIds, deleteSelectedRows, responseData, setResponseData} = props;
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        if (isButtonLoading && responseData) {
            setIsButtonLoading(false);
            deleteSelectedRows();
        }
    }, [responseData, setResponseData]);

    const onButtonClick = () => {
        console.log("selected ids: ", selectedIds);
        setIsButtonLoading(true);
        postUpdatePrices(selectedIds, setResponseData);
    }

    if (count === 0) {
        return (<></>);
    } else if (count === 1) {
        return (
            <Button type={"dashed"} onClick={onButtonClick} loading={isButtonLoading}>Обновить цену товара</Button>
        );
    } else {
        return (
            <Button type={"dashed"} onClick={onButtonClick} loading={isButtonLoading}>Обновить цены
                товаров: {count}</Button>
        );
    }
}

const GoodCatalog = () => {
    const [responseData, setResponseData] = useState(null);
    const [paginationPage, setPaginationPage] = useState(1);
    const [paginationStep, setPaginationStep] = useState(10);
    const [paginationTotal, setPaginationTotal] = useState(100);
    const [goodsData, isGoodsDataLoading, goodsDataError] = useGoods(
        [responseData, paginationPage, paginationStep],
        false, false, paginationPage, paginationStep);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChartModalOpen, setIsChartModalOpen] = useState(false);
    const [chartSeries, setChartSeries] = useState([]);
    const [defaultValues, setDefaultValues] = useState({type: "add"});
    const [isAddGoodModalOpen, setIsAddGoodModalOpen] = useState(false);
    const [competitorDefaultValues, setCompetitorDefaultValues] = useState({type: "add"});
    const [isAddCompetitorModalOpen, setIsAddCompetitorModalOpen] = useState(false);

    useEffect(() => {
        if (goodsData) {
            setPaginationTotal(goodsData.paginationTotal);
            console.log(paginationTotal);
        }

    }, [goodsData]);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    console.log("goods", goodsData);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Категория',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Товар',
            dataIndex: 'good',
            key: 'good',
        },
        {
            title: 'Текущая цена',
            dataIndex: 'currentPrice',
            key: 'currentPrice',
            render: (currentPrice, record) => (
                <Text strong style={{color: "#2222ff"}}>{currentPrice}</Text>
            ),
        },
        {
            title: 'Изменение цены за последние 24 часа',
            dataIndex: 'priceDiff24',
            key: 'priceDiff24',
            render: (diff, record) => (
                <Space size="middle">
                    <DiffPrice diff={diff}/>
                </Space>
            ),
        },
        {
            title: 'Минимальная цена',
            dataIndex: 'minPrice',
            key: 'minPrice',
        },
        {
            title: 'Максимальная цена',
            dataIndex: 'maxPrice',
            key: 'maxPrice',
        },
        {
            title: 'Ссылка на товар (WB)',
            dataIndex: 'goodWbUrl',
            key: 'goodWbUrl',
            align: 'center',
            render: (link) => (
                <Button style={{display: "table-cell", color: "#7f039e"}} target="_blank" type={"link"} href={link}>Перейти</Button>
            ),
        },
        {
            title: 'Ссылка на товар (Ozon)',
            dataIndex: 'goodOzonUrl',
            key: 'goodOzonUrl',
            align: 'center',
            render: (link) => (
                <Button style={{display: "table-cell", color: "#03089e"}} target="_blank" type={"link"} href={link}>Перейти</Button>
            ),
        },
        {
            title: 'Действия',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <Flex gap={"small"}>
                    <Tooltip title={"График изменения цен"}>
                        <Button icon={<LineChartOutlined/>} onClick={() => {
                            setChartSeries(record.chartData);
                            setIsChartModalOpen(true);
                        }}></Button>
                    </Tooltip>
                    <Tooltip title={"Добавить конкурента"}>
                        <Button icon={<PlusOutlined/>} onClick={() => {
                            setCompetitorDefaultValues({
                                type: "add",
                                goodSku: record.id
                            });
                            setIsAddCompetitorModalOpen(true);
                        }}></Button>
                    </Tooltip>
                    <Tooltip title={"Изменить товар"}>
                        <Button icon={<EditOutlined/>} onClick={() => {
                            setDefaultValues({
                                type: "edit",
                                ...record
                            });
                            setIsAddGoodModalOpen(true);
                        }}></Button>
                    </Tooltip>
                </Flex>
            ),
        },
        // {
        //     title: 'Изменить',
        //     dataIndex: 'change',
        //     key: 'change',
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <Button type={"dashed"} onClick={() => {
        //                 // setModalGoodId(record.id);
        //                 // setIsModalOpen(true);
        //             }} icon={<EditOutlined/>}>
        //             </Button>
        //         </Space>
        //     ),
        // },
    ];

    const expandedRowRender = (good) => {
        const columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: "Товар",
                dataIndex: "competitorName",
                key: "competitorName"
            },
            {
                title: 'Текущая цена',
                dataIndex: 'currentPrice',
                key: 'currentPrice',
                render: (currentPrice, record) => (
                    <Text strong style={{color: "#2222ff"}}>{currentPrice}</Text>
                ),
            },
            {
                title: "Актуальность цены",
                dataIndex: "priceActuality",
                key: "priceActuality"
            },
            {
                title: 'Площадка',
                dataIndex: 'marketplace',
                key: 'marketplace',
            },
            {
                title: 'Ссылка на товар',
                dataIndex: 'competitorUrl',
                key: 'competitorUrl',
                align: 'center',
                render: (link) => (
                    <Button style={{display: "table-cell"}} target="_blank" type={"link"} href={link}>Перейти</Button>
                ),
            },
            {
                title: 'Действия',
                key: 'actions',
                align: 'center',
                render: (_, record) => (
                    <Flex gap={"small"} justify={"center"}>
                        <Tooltip title={"Изменить конкурента"}>
                            <Button icon={<EditOutlined/>} onClick={() => {
                                setCompetitorDefaultValues({
                                    type: "edit",
                                    ...record
                                });
                                setIsAddCompetitorModalOpen(true);
                            }}></Button>
                        </Tooltip>
                    </Flex>
                ),
            },
        ];

        const data = good.competitors ? good.competitors.map((competitor) => ({
            key: competitor.id,
            id: competitor.id,
            competitorName: competitor.name,
            competitorUrl: competitor.link,
            currentPrice: competitor.price,
            marketplace: competitor.marketplace,
            priceActuality: competitor.priceActuality,
            goodId: good.id
        })) : [];

        return (good.competitors.length > 0) ? <ConfigProvider
            theme={{
                components: {
                    Table: {
                        "colorBgContainer": "#ececec"
                    },
                },
            }}
        >
            <Table columns={columns} dataSource={data} pagination={false}/>
        </ConfigProvider> : <></>;
    }

    const data = goodsData ? goodsData.data.map((good) => {
        return {
            key: good.id,
            id: good.id,
            wbSku: good.wbSku,
            ozonSku: good.ozonSku,
            category: good.category,
            good: good.name,
            minPrice: good.minPrice,
            maxPrice: good.maxPrice,
            competitor: good.competitor,
            goodWbUrl: good.wbLink,
            goodOzonUrl: good.ozonLink,
            currentPrice: good.price,
            competitors: good.competitors,
            priceDiff24: good.priceDiff,
            chartData: good.chartData
        }
    }) : [];


    return (
        <Flex vertical gap={"small"} align={"center"} style={{width: "100vw"}}>
            <Flex gap={"middle"}>
                <Button onClick={() => {
                    setIsModalOpen(true);
                }}>Импортировать товары</Button>
                <Button href={apiURL + "/export"}>Экспортировать цены</Button>
                <Button onClick={() => {
                    setDefaultValues({type: "add"});
                    setIsAddGoodModalOpen(true);
                }}>Добавить товар</Button>
                <DeleteGoodButton count={selectedRowKeys.length} deleteSelectedRows={() => setSelectedRowKeys([])}
                                  goodIds={selectedRowKeys} responseData={responseData}
                                  setResponseData={setResponseData}/>
                <UpdateGoodPriceButton count={selectedRowKeys.length} deleteSelectedRows={() => setSelectedRowKeys([])}
                                       selectedIds={selectedRowKeys} responseData={responseData}
                                       setResponseData={setResponseData}/>
            </Flex>
            <Table loading={isGoodsDataLoading} rowSelection={rowSelection} columns={columns} dataSource={data}
                   expandable={{expandedRowRender, defaultExpandAllRows: true}}
                   pagination={false}/>
            <Pagination defaultCurrent={1} total={paginationTotal} onChange={(page, step) => {
                setPaginationStep(step);
                setPaginationPage(page);
            }}
            />
            <ImportGoodsModal responseData={responseData} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}
                              setResponseData={setResponseData}/>
            <AddGoodForm isModalOpen={isAddGoodModalOpen} setIsModalOpen={setIsAddGoodModalOpen}
                         defaultValues={defaultValues} responseData={responseData} setResponseData={setResponseData}/>
            <AddCompetitorForm isModalOpen={isAddCompetitorModalOpen} setIsModalOpen={setIsAddCompetitorModalOpen}
                               defaultValues={competitorDefaultValues} responseData={responseData}
                               setResponseData={setResponseData}/>
            <ChartModal series={chartSeries} isModalOpen={isChartModalOpen} setIsModalOpen={setIsChartModalOpen}/>
        </Flex>
    );
}

export default GoodCatalog;
