import {Button, Flex, Space, Statistic, Table, Typography} from "antd";
import {BiDownArrowAlt, BiUpArrowAlt} from "react-icons/bi";
import {ArrowDownOutlined, ArrowUpOutlined, CheckCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import ChangePriceModal from "./changepricemodal";
import {useState} from "react";
import {useGoods} from "../../hooks/api";
import DiffPrice from "./diffprice";

const {Text} = Typography;

export const PriceDiffTableOutOfStock = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalGood, setModalGood] = useState(null);
    const [goodsData, isGoodsDataLoading, goodsDataError] = useGoods([], false, true);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Товар',
            dataIndex: 'good',
            key: 'good',
        },
        {
            title: 'Актуальная цена',
            dataIndex: 'actualPrice',
            key: 'actualPrice',
        },
        {
            title: 'Средняя цена конкурентов на рынке',
            dataIndex: 'averageCompetitorsPrice',
            key: 'averageCompetitorsPrice',
        },
        {
            title: 'Рекомендованная цена',
            dataIndex: 'recommendedOofPrice',
            key: 'recommendedOofPrice',
            render: (text) => (
                <Text strong>{text}</Text>
            )
        },
        {
            title: 'Изменить стоимость',
            dataIndex: 'changePrice',
            align: 'center',
            key: 'changePrice',
            render: (_, record) => (
                <Space.Compact size="middle">
                    <Button type={"dashed"} onClick={() => {
                        setModalGood(record);
                        setIsModalOpen(true);
                    }}>
                        Изменить
                    </Button>
                    <Button onClick={() => {
                    }} icon={<CheckCircleOutlined/>}>
                    </Button>
                </Space.Compact>
            ),
        },
    ];

    const data = goodsData ? goodsData.data.map((good) => {
        return {
            key: good.id,
            id: good.sku,
            good: good.name,
            actualPrice: good.price,
            averageCompetitorsPrice: good.averageCompetitorsPrice,
            priceDiff24: good.priceDiff,
            recommendedOofPrice: good.recommendedOofPrice,
            competitors: good.competitors,
            chartData: good.chartData,
            analyticsData: good.analyticsData
        }
    }) : [];

    return (
        <Flex vertical align={"center"} justify={"center"} style={{
            width: '98vw'
        }}>
            <Table columns={columns} dataSource={data} style={{
                minWidth: '98vw'
            }} loading={isGoodsDataLoading}/>
            <ChangePriceModal loading={isGoodsDataLoading} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}
                              good={modalGood}/>
        </Flex>
    );

}

export const PriceDiffTable = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalGood, setModalGood] = useState(null);
    const [goodsData, isGoodsDataLoading, goodsDataError] = useGoods([], true);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Товар',
            dataIndex: 'good',
            key: 'good',
        },
        {
            title: 'Актуальная цена',
            dataIndex: 'actualPrice',
            key: 'actualPrice',
        },
        {
            title: 'Средняя цена конкурентов на рынке',
            dataIndex: 'averageCompetitorsPrice',
            key: 'averageCompetitorsPrice',
        },
        {
            title: 'Изменение цены за последние 24 часа',
            dataIndex: 'priceDiff24',
            key: 'priceDiff24',
            render: (diff, record) => (
                <Space size="middle">
                    <DiffPrice diff={diff}/>
                </Space>
            ),
        },
        {
            title: 'Рекомендованная цена',
            dataIndex: 'recommendedPrice',
            key: 'recommendedPrice',
            render: (text) => (
                <Text strong>{text}</Text>
            )
        },
        {
            title: 'Изменить стоимость',
            dataIndex: 'changePrice',
            align: 'center',
            key: 'changePrice',
            render: (_, record) => (
                <Space.Compact size="middle">
                    <Button type={"dashed"} onClick={() => {
                        setModalGood(record);
                        setIsModalOpen(true);
                    }}>
                        Изменить
                    </Button>
                    <Button onClick={() => {
                    }} icon={<CheckCircleOutlined/>}>
                    </Button>
                </Space.Compact>
            ),
        },
    ];

    const data = goodsData ? goodsData.data.map((good) => {
        return {
            key: good.id,
            id: good.sku,
            good: good.name,
            actualPrice: good.price,
            averageCompetitorsPrice: good.averageCompetitorsPrice,
            priceDiff24: good.priceDiff,
            recommendedPrice: good.recommendedPrice,
            competitors: good.competitors,
            chartData: good.chartData,
            analyticsData: good.analyticsData
        }
    }) : [];

    return (
        <Flex vertical align={"center"} justify={"center"} style={{
            width: '98vw'
        }}>
            <Table columns={columns} dataSource={data} style={{
                minWidth: '98vw'
            }} loading={isGoodsDataLoading}/>
            <ChangePriceModal loading={isGoodsDataLoading} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}
                              good={modalGood}/>
        </Flex>
    );

}
