import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {Statistic} from "antd";
import {RiInformationOffLine} from "react-icons/ri";


const DiffPrice = (props) => {
    const {diff} = props;
    if (diff.type === "up") {
        return (
            <Statistic
                value={diff.value}
                valueStyle={{
                    color: '#3f8600',
                    fontSize: '12pt'
                }}
                prefix={<ArrowUpOutlined/>}
                suffix="%"
            />
        );
    } else if (diff.type === "down") {
        return (
            <Statistic
                value={diff.value}
                valueStyle={{
                    color: '#cf1322',
                    fontSize: '12pt'
                }}
                prefix={<ArrowDownOutlined/>}
                suffix="%"
            />
        );
    } else {
        return (
            <Statistic
                value={"-"}
                valueStyle={{
                    color: '#454545',
                    fontSize: '12pt'
                }}
                prefix={<RiInformationOffLine />}
                suffix="%"
            />
        );
    }
}

export default DiffPrice;