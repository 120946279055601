import {Form, Input, Checkbox, Button, Flex} from "antd";
import {useContext, useEffect, useState} from "react";
import {postLogin} from "../../hooks/api";
import {useNavigate} from "react-router-dom";
import {MessageContext} from "../../pages/App/App";
const Login = () => {
    const [loginData, setLoginData] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const {messageApi} = useContext(MessageContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (loginData && loginData.status === "success") {
            messageApi.success(loginData.message);
            navigate("/");
            window.location.reload();
        } else if (loginData && loginData.status === "error") {
            messageApi.error(loginData.message);
        }
    }, [loginData, loginError]);

    return (
        <Flex style={{
        }}>
            <Form
                name="basic"
                style={{
                    width: '60vh',
                }}
                layout={"vertical"}
                initialValues={{
                    remember: true,
                }}
                onFinish={({username, password}) => {
                    console.log(username, password);
                    postLogin(username, password, setLoginData, setLoginError);
                }}
                onFinishFailed={(e) => console.log(e)}
                autoComplete="off"
            >
                <Form.Item
                    label="Логин"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите логин!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите пароль!',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Checkbox>Запомнить меня</Checkbox>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </Flex>
    );
}


export default Login;