import {Form, Input, InputNumber, Modal, Select} from "antd";
import {useEffect, useState} from "react";
import {postCreateGood, postCreateGoodCompetitor} from "../../hooks/api";


const AddCompetitorForm = (props) => {
    const {isModalOpen, setIsModalOpen, defaultValues, responseData, setResponseData} = props;
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            goodId: defaultValues.goodId,
            sku: defaultValues.id,
            competitorName: defaultValues.competitorName,
            competitorUrl: defaultValues.competitorUrl,
            marketplace: defaultValues.marketplace,
        });
    }, [form, defaultValues]);

    const modal_name = defaultValues.type === "add" ? "Добавить конкурента" : "Изменить конкурента";
    const modal_ok_button_text = defaultValues.type === "add" ? "Добавить" : "Изменить";
    const is_sku_disabled = (defaultValues.type !== "add");

    return (
        <Modal title={modal_name} open={isModalOpen} onCancel={() => setIsModalOpen(false)}
               width={"100vh"}
               cancelText={"Отмена"}
               okText={modal_ok_button_text}
               okButtonProps={{
                   autoFocus: true,
                   htmlType: 'submit',
               }}
               modalRender={(dom) => (
                   <Form
                       form={form}
                       name="basic"
                       labelCol={{
                           // span: 8,
                       }}
                       wrapperCol={{
                           // span: 16,
                       }}
                       style={{
                           // maxWidth: 600,
                       }}
                       layout={"vertical"}
                       initialValues={{
                           goodId: defaultValues.goodId,
                           sku: defaultValues.id,
                           competitorName: defaultValues.competitorName,
                           competitorUrl: defaultValues.competitorUrl,
                           marketplace: defaultValues.marketplace,
                       }}
                       onFinish={(e) => {
                           postCreateGoodCompetitor(e, setResponseData);
                       }}
                       onFinishFailed={(e) => console.log(e)}
                       autoComplete="off"
                   >
                       {dom}
                   </Form>
               )}
        >
            <Form.Item
                label="ID товара"
                name="goodId"
                rules={[
                    {
                        required: true,
                        message: 'Введите ID!',
                    },
                ]}
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="SKU"
                name="sku"
                rules={[
                    {
                        required: true,
                        message: 'Введите SKU!',
                    },
                ]}
            >
                <Input disabled={is_sku_disabled}/>
            </Form.Item>
            <Form.Item
                label="Название товара конкурента"
                name="competitorName"
                rules={[
                    {
                        required: true,
                        message: 'Введите название товара!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Маркетплейс"
                name="marketplace"
                rules={[
                    {
                        required: true,
                        message: 'Выберите маркетплейс!'
                    },
                ]}
            >
                <Select options={[
                    {
                        value: "ozon",
                        label: "Озон"
                    },
                    {
                        value: "wb",
                        label: "Вайлдберриз"
                    }
                ]}/>
            </Form.Item>
            <Form.Item
                label="Ссылка на товар конкурента"
                name="competitorUrl"
                rules={[
                    {
                        required: true,
                        message: 'Введите ссылку на товар!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
        </Modal>
    );
}

export default AddCompetitorForm;