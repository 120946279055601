import {Button, Card, Flex, Form, Input, Typography} from "antd";
import {useEffect, useState} from "react";
import {postSettings, useSettings} from "../../hooks/api";

const {Title} = Typography;


export const SettingsCard = () => {
    const [responseData, setResponseData] = useState(null);
    const [settings, isSettingsLoading, settingsError] = useSettings([responseData]);
    const [defaultValues, setDefaultValues] = useState({
        limit_min: 0,
        limit_max: 0,
        auto_limit_min: 0,
        auto_limit_max: 0,
        max_competitor_price_change: 0,
        out_of_stock_days: 0,
        out_of_stock_raise_percent: 0
    });
    const [form] = Form.useForm();

    useEffect(() => {
        if (settings) {
            setDefaultValues(settings);
        }
    }, [settings, responseData, setResponseData])

    useEffect(() => {
        form.setFieldsValue(defaultValues);
    }, [form, defaultValues]);


    return (
        <Card>
            <Flex vertical>
                <Title level={4}>Настройки</Title>
                <Form
                    name="settings"
                    form={form}
                    style={{
                        // maxWidth: 600,
                    }}
                    layout={"vertical"}
                    initialValues={defaultValues}
                    onFinish={(e) => {
                        postSettings(e, setResponseData)
                    }}
                    onFinishFailed={(e) => console.log(e)}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Максимальное изменение цены"
                        name="limit_change"
                        rules={[
                            {
                                required: true,
                                message: 'Укажите максимальное изменение цены!',
                            },
                        ]}>
                        <Input placeholder="5%"/>
                    </Form.Item>
                    <Form.Item
                        label="Порог автоматического изменения цены"
                        name="max_competitor_price_change"
                        rules={[
                            {
                                required: true,
                                message: 'Укажите максимальное изменение цены конкурента!',
                            },
                        ]}>
                        <Input placeholder="3%"/>
                    </Form.Item>
                    <Form.Item
                        label="Количество дней для срабатывания out-of-stock механики"
                        name="out_of_stock_days"
                        rules={[
                            {
                                required: true,
                                message: 'Укажите количество дней для срабатывания out-of-stock',
                            },
                        ]}>
                        <Input placeholder="3%"/>
                    </Form.Item>
                    <Form.Item
                        label="Процент повышения стоимости при срабатывании out-of-stock механики"
                        name="out_of_stock_raise_percent"
                        rules={[
                            {
                                required: true,
                                message: 'Укажите процент повышения стоимости при срабатывании out-of-stock механики',
                            },
                        ]}>
                        <Input placeholder="3%"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type={"primary"} htmlType={"submit"}>Сохранить</Button>
                    </Form.Item>
                </Form>
            </Flex>
        </Card>
    );
}