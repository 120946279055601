import Login from "../../components/login/login";
import {Flex, Typography} from "antd";
import AIChat from "../../components/aichat/aichat";

const {Title} = Typography;

const ChatPage = () => {

    return (
        <Flex vertical align={"center"} justify={"center"} style={{
            height: '85vh',
            width: '100%',
        }} gap={"large"}>
            <Title level={1}>Чат GPT-4o</Title>
            <AIChat/>
        </Flex>
    );
}

export default ChatPage;