import {Col, Row, Flex, Image, Typography, Avatar, Divider, Skeleton} from "antd";
import {RightSquareOutlined, UserOutlined} from "@ant-design/icons";
import {useEffect, useRef} from "react";

const {Text} = Typography;


const ChatMessage = (props) => {
    const {text, isAnswer, isSkeleton} = props;

    const getAvatar = () => {
        if (isAnswer) {
            return (
                <Avatar icon={<RightSquareOutlined/>} size={"large"} style={{
                    backgroundColor: '#87d068',
                }}/>
            );
        }
        return (
            <Avatar icon={<UserOutlined/>} size={"large"}/>
        );
    }


    return (
        <>
            <Flex gap={"large"} justify={"flex-start"} align={"center"} style={{paddingLeft: "2%"}}>
                {getAvatar()}
                <Flex style={{width: "80%"}}>
                    {isSkeleton ?
                        <Skeleton style={{}} active loading/>
                        : (<Text style={{textAlign: "left"}}>{text}</Text>)
                    }
                </Flex>
            </Flex>
            <Divider/>


        </>
    );
}

export default ChatMessage;

