import {Form, Input, InputNumber, Modal, Select} from "antd";
import {useEffect, useState} from "react";
import {postCreateGood} from "../../hooks/api";


const AddGoodForm = (props) => {
    const {isModalOpen, setIsModalOpen, defaultValues, responseData, setResponseData} = props;
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            id: defaultValues.id,
            wbSku: defaultValues.wbSku,
            ozonSku: defaultValues.ozonSku,
            category: defaultValues.category,
            goodName: defaultValues.good,
            minPrice: defaultValues.minPrice,
            maxPrice: defaultValues.maxPrice,
            goodWbUrl: defaultValues.goodWbUrl,
            goodOzonUrl: defaultValues.goodOzonUrl,
        });
    }, [form, defaultValues]);

    const modal_name = defaultValues.type === "add" ? "Добавить товар" : "Изменить товар";
    const modal_ok_button_text = defaultValues.type === "add" ? "Добавить" : "Изменить";
    const is_sku_disabled = (defaultValues.type !== "add");

    return (
        <Modal title={modal_name} open={isModalOpen} onCancel={() => setIsModalOpen(false)}
               width={"100vh"}
               cancelText={"Отмена"}
               okText={modal_ok_button_text}
               okButtonProps={{
                   autoFocus: true,
                   htmlType: 'submit',
               }}
               modalRender={(dom) => (
                   <Form
                       form={form}
                       name="basic"
                       labelCol={{
                           // span: 8,
                       }}
                       wrapperCol={{
                           // span: 16,
                       }}
                       style={{
                           // maxWidth: 600,
                       }}
                       layout={"vertical"}
                       initialValues={{
                           id: defaultValues.id,
                           wbSku: defaultValues.wbSku,
                           ozonSku: defaultValues.ozonSku,
                           category: defaultValues.category,
                           goodName: defaultValues.good,
                           minPrice: defaultValues.minPrice,
                           maxPrice: defaultValues.maxPrice,
                           goodWbUrl: defaultValues.goodWbUrl,
                           goodOzonUrl: defaultValues.goodOzonUrl,
                       }}
                       onFinish={(e) => {
                           postCreateGood(e, setResponseData);
                       }}
                       onFinishFailed={(e) => console.log(e)}
                       autoComplete="off"
                   >
                       {dom}
                   </Form>
               )}
        >
            <Form.Item
                label="ID"
                name="id"
                rules={[
                    {
                        required: true,
                        message: 'Введите ID!',
                    },
                ]}
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="WB SKU"
                name="wbSku"
                rules={[
                    {
                        required: true,
                        message: 'Введите SKU Wildberries!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Ozon SKU"
                name="ozonSku"
                rules={[
                    {
                        required: true,
                        message: 'Введите SKU Ozon!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Категория"
                name="category"
                rules={[
                    {
                        required: true,
                        message: 'Введите категорию!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Название товара"
                name="goodName"
                rules={[
                    {
                        required: true,
                        message: 'Введите название товара!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Минимальная цена"
                name="minPrice"
                rules={[
                    {
                        required: true,
                        message: 'Введите минимальную цену!',
                    },
                ]}
            >
                <InputNumber defaultValue={100} step={1}/>
            </Form.Item>
            <Form.Item
                label="Максимальная цена"
                name="maxPrice"
                rules={[
                    {
                        required: true,
                        message: 'Введите максимальную цену!',
                    },
                ]}
            >
                <InputNumber defaultValue={100} step={1}/>
            </Form.Item>
            <Form.Item
                label="Ссылка на товар в Wildberries"
                name="goodWbUrl"
                rules={[
                    {
                        required: true,
                        message: 'Введите ссылку на товар в Wildberries!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Ссылка на товар в Ozon"
                name="goodOzonUrl"
                rules={[
                    {
                        required: true,
                        message: 'Введите ссылку на товар в Ozon!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
        </Modal>
    );
}

export default AddGoodForm;