import {Flex, Spin, Typography} from "antd";
import DayStatCard from "../../components/statistics/daystatcard";
import {PriceDiffTable, PriceDiffTableOutOfStock} from "../../components/statistics/pricedifftable";
import {useUser} from "../../hooks/api";
import {useNavigate} from "react-router-dom";

const {Title} = Typography;

const MainPage = () => {
    const [userData, isUserLoading, userError] = useUser();
    const navigate = useNavigate();

    if (isUserLoading) {
        return (<Spin/>);
    }

    if (userData && userData.isAnonymous) {
        navigate("/login");
    }
    return (
        <Flex vertical align={"flex-start"}>
            <Title level={5} style={{
                color: '#555',
                paddingLeft: '3vh'
            }}>
                Ежедневная статистика
            </Title>
            <Flex gap={"middle"} justify={"space-evenly"}>
                <DayStatCard description={"Суммарные продажи на WB / мес"} value={15000000}/>
                <DayStatCard description={"Суммарные продажи на OZON / мес"} value={4000000}/>
                <DayStatCard description={"Дополнительная ценность от платформы"} value={550000}/>
                <DayStatCard description={"Среднее время изменения нашей цены после изменения цены конкурента"}
                             value={"45 минут"}/>
            </Flex>
            <Title level={5} style={{
                color: '#555',
                paddingLeft: '3vh'
            }}>
                Заканчивающиеся товары
            </Title>
            <PriceDiffTableOutOfStock/>
            <Title level={5} style={{
                color: '#555',
                paddingLeft: '3vh'
            }}>
                Товары с изменением цены больше {userData && userData.settings && userData.settings.max_competitor_price_change}% за
                последние 24 часа
            </Title>
            <PriceDiffTable/>

        </Flex>
    );
}

export default MainPage;