import {Button, Flex, Space} from "antd";
import GoodCatalog from "../../components/goodcatalog/goodcatalog";
import {ChangePriceLimitsCard, SettingsCard} from "../../components/goodcatalog/changelimitscard";
import AdminPanel from "../../components/admin/adminpanel";


const AdminPage = () => {

    return (
        <Flex gap={"large"}>
            <AdminPanel/>
        </Flex>
    );
}

export default AdminPage;