import {Card, Statistic} from "antd";


const DayStatCard = (props) => {
    const {value, description} = props;

    return (
        <Card>
            <Statistic title={description} value={value} valueStyle={{
                color: '#3f8600',
            }}/>
        </Card>
    );
}


export default DayStatCard;