import ChatMessage from "./chatmessage";
import {useEffect, useRef, useState} from "react";
import {Flex} from "antd";
import ChatInput from "./chatinput";

const AIChat = () => {
    const [chatMessages, setChatMessages] = useState([{
        isAnswer: true,
        text: "Мне можно задавать различные вопросы...",
        loading: false
    }]);
    // {isAnswer: true, text: "dwdad", loading: false}
    // {isAnswer: true, text: null, loading: true}
    // {isAnswer: false, text: "dwdad", loading: false}
    const messagesEndRef = useRef(null);
    console.log(chatMessages);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        console.log("Scrolling to bottom");
        scrollToBottom();
    }, [chatMessages, setChatMessages]); // Only scroll to end once when the component mounts

    return (
        <>
            <Flex vertical align={"center"}
                  style={{overflowY: 'hidden', overflowX: 'hidden', height: '100%', width: "100%"}}>
                <Flex ref={messagesEndRef} vertical style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    height: '90vh',
                    width: "90%",
                    paddingTop: "7%"
                }}>
                    {/*<Flex vertical>*/}
                    {chatMessages.map((message) => (
                        <ChatMessage text={message.text} isAnswer={message.isAnswer} isSkeleton={message.loading}
                                     key={"chatmessage_" + message.text}/>
                    ))}
                </Flex>
                <ChatInput chatMessages={chatMessages} setChatMessages={setChatMessages}/>
            </Flex>
        </>
    );

}

export default AIChat;
