import {Alert, Button, Divider, Flex, Input, Modal, Space, Table, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {postCreateUser, postDeleteUsers, useUsers} from "../../hooks/api";
import ImportGoodsModal from "../goodcatalog/importgoodsmodal";
import {useContext, useEffect, useState} from "react";
import {MessageContext} from "../../pages/App/App";

const {Title} = Typography;


const DeleteUserButton = (props) => {
    const {count, usernames, deleteSelectedRows, responseData, setResponseData} = props;

    const onButtonClick = () => {
        console.log("users to delete: ", usernames);
        postDeleteUsers(usernames, setResponseData);
        deleteSelectedRows();
    }

    if (count === 0) {
        return (<></>);
    } else if (count === 1) {
        return (
            <Button onClick={onButtonClick} danger>Удалить пользователя</Button>
        );
    } else {
        return (
            <Button onClick={onButtonClick} danger>Удалить пользователей: {count}</Button>
        );
    }
}

const AddUserModal = (props) => {
    const {isModalOpen, setIsModalOpen, defaultUsername, defaultName, responseData, setResponseData} = props;
    const [userUsername, setUserUsername] = useState(defaultUsername);
    const [userName, setUserName] = useState(defaultName);
    const [userPassword, setUserPassword] = useState("");

    const {messageApi} = useContext(MessageContext);


    useEffect(() => {
        setUserUsername(defaultUsername);
        setUserName(defaultName);
        setUserPassword("");
        if (responseData && responseData.status === "success") {
            messageApi.success(responseData.message);
        } else if (responseData && responseData.status === "error") {
            messageApi.error(responseData.message);
        }
        setResponseData(null);
    }, [messageApi, responseData, setResponseData, isModalOpen, setIsModalOpen]);

    return (
        <Modal title={"Управление пользователями"}
               open={isModalOpen} onCancel={() => setIsModalOpen(false)}
               onOk={() => setIsModalOpen(false)}
            // width={"80%"}
               cancelButtonProps={{style: {display: 'none'}}}
               okButtonProps={{style: {display: 'none'}}}
               okText={"OK"}
        >
            <Flex vertcal gap={"middle"}>
                    <Input placeholder={"Логин"} value={userUsername}
                           onChange={(e) => setUserUsername(e.target.value)}/>

                    <Input placeholder={"ФИО"} value={userName}
                           onChange={(e) => setUserName(e.target.value)}/>

                <Input.Password placeholder={"Пароль"} onChange={(e) => setUserPassword(e.target.value)}/>
                <Button onClick={() => {
                    postCreateUser(userUsername, userName, userPassword, setResponseData);
                }}>Создать</Button>
            </Flex>
            <Divider/>
            <Alert message="Помните, что пароли хранятся в захешированном виде и вы не можете их видеть!" type="info"/>
        </Modal>
    );
}

const AdminPanel = () => {
    const [responseData, setResponseData] = useState(null);
    const [users, isUsersLoading, usersError] = useUsers([responseData]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [defaultAddUsername, setDefaultAddUsername] = useState("");
    const [defaultAddName, setDefaultAddName] = useState("");


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: 'Логин',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Изменить пароль',
            dataIndex: 'change',
            align: 'right',
            key: 'change',
            render: (_, record) => (
                <Space size="middle">
                    <Button type={"dashed"} onClick={() => {
                        // setModalGoodId(record.id);
                        setDefaultAddUsername(record.username);
                        setDefaultAddName(record.name);
                        setIsModalOpen(true);
                        // setIsModalOpen(true);
                    }} icon={<EditOutlined/>}>
                    </Button>
                </Space>
            ),
        },
    ];

    const data = users ? users.data.map((user) => ({
        key: user.username,
        username: user.username,
        name: user.name
    })) : [];

    return (
        <Flex vertical gap={"small"} align={"center"} style={{width: "100vw"}}>
            <Title level={3}>Пользователи</Title>
            <Flex gap={"middle"} justify={"space-evenly"}>
                <Button onClick={() => {
                    setDefaultAddUsername("");
                    setDefaultAddName("");
                    setIsModalOpen(true);
                }}>
                    Добавить пользователя
                </Button>
                <DeleteUserButton usernames={selectedRowKeys} count={selectedRowKeys.length}
                                  responseData={responseData} setResponseData={setResponseData}
                                  deleteSelectedRows={() => setSelectedRowKeys([])}/>
            </Flex>
            <Table rowSelection={rowSelection} bordered loading={isUsersLoading} columns={columns} dataSource={data}
                   style={{width: "95%"}}/>
            <AddUserModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}
                          defaultUsername={defaultAddUsername} setResponseData={setResponseData}
                          responseData={responseData} defaultName={defaultAddName}/>
        </Flex>
    );
}

export default AdminPanel;