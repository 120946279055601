import {Card, Flex, Select, Typography} from "antd";
import {useCharts, useGoods} from "../../hooks/api";
import {useState} from "react";
import {GoodChart, RelativeGoodChart, TopChart} from "../goodcatalog/chartmodal";

const {Text} = Typography;

const removeDuplicates = (array) => {

    const jsonObject = array.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    return Array.from(uniqueSet).map(JSON.parse);

}

const SelectParam = ({param, source, setChange}) => {
    return (
        <Select
            showSearch
            placeholder={"Выберите " + param}
            optionFilterProp="label"
            onChange={(value) => setChange(value)}
            options={source}
        />
    );
}

const Charts = () => {
    const [goods, goodsLoading, goodError] = useGoods();
    const [selectedGood, setSelectedGood] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [chartsData, chartsDataLoading, chartsDataError] = useCharts(
        [selectedGood, setSelectedGood, selectedCategory, setSelectedCategory],
        selectedGood, selectedCategory);

    const goodsSource = goods && goods.data ? goods.data.map((good) => ({
        value: good.id,
        label: good.name
    })) : [];

    const goodsCategoriesRaw = goods && goods.data ? goods.data.map((good) => ({
        value: good.category,
        label: good.category
    })) : [];

    const goodsCategories = removeDuplicates(goodsCategoriesRaw);


    return (
        <Flex vertical gap={"middle"}>
            <Flex gap={"middle"}>
                <Card>
                    <Flex vertical gap={"small"}>
                        <Text strong>Изменение цены товара и конкурентов</Text>
                        <SelectParam param={"товар"} source={goodsSource} setChange={setSelectedGood}/>
                        <GoodChart loading={!(chartsData && chartsData.data)} size={{
                            width: 500,
                            height: 400
                        }} series={chartsData && chartsData.data.goodChart ? chartsData.data.goodChart : {
                            series: [],
                            axisData: []
                        }}/>
                    </Flex>
                </Card>
                <Card>
                    <Flex vertical gap={"small"}>
                        <Text strong>Изменение цены товаров в категории относительно конкурентов</Text>
                        <SelectParam param={"категорию"} source={goodsCategories} setChange={setSelectedCategory}/>
                        <RelativeGoodChart loading={!(chartsData && chartsData.data)} size={{
                            width: 500,
                            height: 400
                        }} series={chartsData && chartsData.data.categoryChart ? chartsData.data.categoryChart : {
                            series: [],
                            dates: []
                        }}/>
                    </Flex>
                </Card>
            </Flex>
            <Card>
                <Flex vertical gap={"small"}>
                    <Text strong>Изменение цен всех товаров относительно конкурентов</Text>
                    <RelativeGoodChart loading={!(chartsData && chartsData.data)} size={{
                        width: 1000,
                        height: 400
                    }} series={chartsData && chartsData.data.allChart ? chartsData.data.allChart : {
                        series: [],
                        dates: []
                    }}/>
                </Flex>
            </Card>
            {chartsData && chartsData.data && <Flex vertical gap={"middle"}>
                <Card>
                    <Flex vertical gap={"small"}>
                        <Text strong>Топ 5 товаров с ценой выше рынка</Text>
                        <TopChart loading={!(chartsData && chartsData.data)} size={{
                            width: 1000,
                            height: 400
                        }} series={chartsData && chartsData.data.topChart ? chartsData.data.topChart : {
                            serie: {},
                            keys: []
                        }}/>
                    </Flex>
                </Card>
                <Card>
                    <Flex vertical gap={"small"}>
                        <Text strong>Топ 5 товаров с ценой ниже рынка</Text>
                        <TopChart loading={!(chartsData && chartsData.data)} size={{
                            width: 1000,
                            height: 400
                        }} series={chartsData && chartsData.data.antiTopChart ? chartsData.data.antiTopChart : {
                            serie: {},
                            keys: []
                        }} />
                    </Flex>
                </Card>
            </Flex>
            }
        </Flex>
    );
}

export default Charts;