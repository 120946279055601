import {axisClasses, BarChart, LineChart} from "@mui/x-charts";
import {Card, Flex, Modal, Typography} from "antd";
import {LineOutlined} from "@ant-design/icons";

const {Title, Text} = Typography;

const convertToDates = (dateStrs) => {
    return dateStrs.map(dateStr => new Date(dateStr));
}

const valueFormatter = (date) =>
    date.getHours() === 0
        ? date.toLocaleDateString('ru-RU', {
            month: '2-digit',
            day: '2-digit',
        })
        : date.toLocaleTimeString('ru-RU', {
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });


export const GoodChart = (props) => {
    const {series, size = {width: 900, height: 500}, loading = false} = props;
    return (
        <Flex vertical align={"center"}>
            <Flex gap={"middle"}>
                <Flex gap={"small"}>
                    <LineOutlined style={{color: "#02c235"}}/>
                    <Text type={"secondary"}>Ваш товар</Text>
                </Flex>
                <Flex gap={"small"}>
                    <LineOutlined style={{color: "#545454"}}/>
                    <Text type={"secondary"}>Конкурент</Text>
                </Flex>
            </Flex>
            <LineChart
                skipAnimation
                loading={loading}
                xAxis={[{
                    valueFormatter,
                    scaleType: "time",
                    data: convertToDates(series.axisData),
                    tickMinStep: 60 * 1000,
                    label: 'Время'
                }]}
                sx={{
                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                        transform: 'translateX(-10px)',
                    },
                }}
                yAxis={
                    [{
                        label: 'Цены (в рублях)',
                    }]
                }
                grid={
                    {
                        vertical: true, horizontal:
                            true
                    }
                }
                series={
                    series.series.map(serie => ({
                        ...serie,
                        type: 'line',
                        // valueFormatter: (value) => (value == null ? 'Нет данных' : value.toString()),
                        connectNulls: true
                    }))
                }
                slotProps={
                    {
                        legend: {
                            hidden: true
                        }
                        ,
                        // Custom loading message
                        loadingOverlay: {
                            message: 'Данные прогружаются..'
                        }
                        ,
                        // Custom message for empty chart
                        noDataOverlay: {
                            message: 'Недостаточно данных для отображения графика.'
                        }
                        ,
                    }
                }
                width={size.width}
                height={size.height}
            />
        </Flex>
    );
}

export const RelativeGoodChart = (props) => {
    const {series, size = {width: 900, height: 500}, loading = false} = props;
    return (
        <Flex vertical align={"center"}>
            <BarChart
                skipAnimation
                loading={loading}
                xAxis={[{
                    valueFormatter,
                    scaleType: "band",
                    data: convertToDates(series.dates),
                    // tickMinStep: 60 * 1000,
                    label: 'Время',

                }]}
                sx={{
                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                        transform: 'translateX(-10px)',
                    },
                }}
                yAxis={
                    [{
                        label: 'Кол-во (шт)',
                    }]
                }
                grid={
                    {
                        vertical: true, horizontal:
                            true
                    }
                }
                series={
                    series.series.map(serie => ({
                        ...serie,
                        stack: 'total'
                    }))
                }
                slotProps={
                    {
                        legend: {
                            hidden: true
                        }
                        ,
                        // Custom loading message
                        loadingOverlay: {
                            message: 'Данные прогружаются..'
                        }
                        ,
                        // Custom message for empty chart
                        noDataOverlay: {
                            message: 'Недостаточно данных для отображения графика.'
                        }
                        ,
                    }
                }
                width={size.width}
                height={size.height}
            />
        </Flex>
    );
}

export const TopChart = (props) => {
    const {series, size = {width: 900, height: 500}, loading = false, reverse = false} = props;
    return (
        <Flex vertical align={"center"}>
            <BarChart
                skipAnimation
                loading={loading}
                yAxis={[{
                    scaleType: "band",
                    data: series && series.keys ? series.keys : [],
                    reverse
                    // Other yAxis configurations
                }]}
                xAxis={[{
                    label: 'Разница (%)',
                }]}
                grid={{
                    vertical: true,
                    horizontal: true,
                }}
                margin={{
                    left: 250,  // Increase the left margin to prevent labels from being cut off
                    right: 20,  // Adjust other margins as needed
                    top: 20,
                    bottom: 40,
                }}
                series={series && series.serie ? [series.serie] : []}
                slotProps={{
                    legend: {
                        hidden: true,
                    },
                    loadingOverlay: {
                        message: 'Данные прогружаются..',
                    },
                    noDataOverlay: {
                        message: 'Недостаточно данных для отображения графика.',
                    },
                }}
                width={size.width}
                height={size.height}
                layout={"horizontal"}
            />
        </Flex>
    );
}

const ChartModal = (props) => {
    const {series, isModalOpen, setIsModalOpen} = props;

    return (
        <Modal title={"График изменения цен за две недели"}
               open={isModalOpen} onCancel={() => setIsModalOpen(false)}
               onOk={() => setIsModalOpen(false)}
               width={"70%"}
               cancelButtonProps={{style: {display: 'none'}}}
               okButtonProps={{style: {display: 'none'}}}
               okText={"OK"}
        >
            <Flex vertical gap={"middle"} align={"center"}>
                <GoodChart series={series}/>
            </Flex>
        </Modal>
    );
}

export default ChartModal;