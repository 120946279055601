import './App.css';
import {Layout, message, Spin} from "antd";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {createContext, useEffect} from "react";
import HeaderContent from "../header/headercontent";
import LoginPage from "../login/loginpage";
import MainPage from "../mainpage/mainpage";
import GoodsAndCompetitorsPage from "../goodsandcompetitors/goodsandcompetitorspage";
import {useUser} from "../../hooks/api";
import AdminPage from "../admin/adminpage";
import ChatPage from "../chat/chatpage";
import ChartsPage from "../chartspage/chartspage";

const {Header, Content} = Layout;
const headerStyle = {
    textAlign: 'center',
    // color: '#',
    // height: 64,
    paddingInline: 48,
    // lineHeight: '64px',
    backgroundColor: '#4096ff',
};

const contentStyle = {
    // textAlign: 'center',
    minHeight: 120,
    padding: "2vh",
    lineHeight: '120px',
    // color: '#fff',
    overflowY: "scroll",
    overflowX: "hidden"
    // backgroundColor: '#0958d9',
};

const siderStyle = {
    // textAlign: 'center',
    // lineHeight: '120px',
    // color: '#fff',
    // backgroundColor: '#1677ff',
};

const footerStyle = {
    textAlign: 'center',
    // color: '#fff',
    // backgroundColor: '#4096ff',
};

const layoutStyle = {
    // borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100%)',
    maxWidth: 'calc(100%)',
    height: '100vh',
}


export const MessageContext = createContext(null);

const App = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [userData, isUserLoading, userError] = useUser();


    const show_error = (text) => {
        messageApi.error(text);
    };

    const show_success = (text) => {
        messageApi.success(text);
    };

    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);

        if (queryParameters.has('error')) {
            show_error(queryParameters.get('text'))
            queryParameters.delete('error')
            queryParameters.delete('text')
        }

        if (queryParameters.has('success')) {
            show_success(queryParameters.get('text'))
            queryParameters.delete('success')
            queryParameters.delete('text')
        }
        navigate(window.location.pathname);
    }, []);


    return (
        <>
            <MessageContext.Provider value={{messageApi: messageApi}}>
                <Layout style={layoutStyle} key={"main_layout"}>
                    <Header
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <HeaderContent isAdmin={userData && userData.isAdmin}
                                       isAnonymous={!userData}/>
                    </Header>
                    <Content style={contentStyle}>
                        <Routes>
                            <Route exact path="/login" element={<LoginPage/>}/>
                            <Route exact path="/" element={<MainPage/>}/>
                            <Route exact path="/goods_and_competitors" element={<GoodsAndCompetitorsPage/>}/>
                            <Route exact path="/limits" element={<GoodsAndCompetitorsPage isAdmin/>}/>
                            <Route exact path="/admin" element={<AdminPage/>}/>
                            <Route exact path="/chat" element={<ChatPage/>}/>
                            <Route exact path="/charts" element={<ChartsPage/>}/>
                        </Routes>
                    </Content>
                    {contextHolder}
                </Layout>
            </MessageContext.Provider>
        </>
    );

}


export default App;
