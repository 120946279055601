import {Button, Flex, Space} from "antd";
import GoodCatalog from "../../components/goodcatalog/goodcatalog";
import {SettingsCard} from "../../components/goodcatalog/changelimitscard";
import AdminPanel from "../../components/admin/adminpanel";


const GoodsAndCompetitorsPage = (props) => {
    const {isAdmin = false} = props;

    if (isAdmin) {
        return (
            <Flex gap={"large"}>

                <Flex vertical gap={"middle"}>
                    <SettingsCard/>
                </Flex>

                <AdminPanel/>
            </Flex>
        );
    } else {
        return (
            <Flex gap={"large"}>
                <GoodCatalog/>
            </Flex>
        );
    }
}

export default GoodsAndCompetitorsPage;