import {useEffect, useState} from "react";
import {BiArch, BiCard} from "react-icons/bi";
import {Button, Flex, Menu, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {MdOutlineAdminPanelSettings, MdOutlineSettingsInputComponent} from "react-icons/md";
import {useUser} from "../../hooks/api";
import {apiURL} from "../../config";
import {AreaChartOutlined, MessageOutlined} from "@ant-design/icons";

const {Title, Text} = Typography;

const HeaderContent = (props) => {
    const {isAdmin = false, isAnonymous = false} = props;

    const [userData, isUserLoading, userError] = useUser();
    const [current, setCurrent] = useState('mainPage');
    const navigate = useNavigate();

    const items = [
        (!isAnonymous && {
            label: 'Главная',
            key: 'mainPage',
            icon: <BiArch/>,
        }),
        (!isAnonymous && {
            label: 'Товары',
            key: 'goodsAndCompetitors',
            icon: <BiCard/>,
        }),
        (!isAnonymous && {
            label: 'Чат GPT-4o',
            key: 'aiChat',
            icon: <MessageOutlined/>,
        }),
        (!isAnonymous && {
            label: 'Графики',
            key: 'charts',
            icon: <AreaChartOutlined/>,
        }),
        (isAdmin && {
            label: 'Админ',
            key: 'limits',
            icon: <MdOutlineAdminPanelSettings/>,
        }),
    ];

    useEffect(() => {
        if (current === "login") {
            navigate('/login');
        } else if (current === "mainPage") {
            navigate('/');
        } else if (current === "goodsAndCompetitors") {
            navigate('/goods_and_competitors');
        } else if (current === "limits") {
            navigate('/limits');
        } else if (current === "admin") {
            navigate('/admin');
        } else if (current === "aiChat") {
            navigate('/chat');
        } else if (current === "charts") {
            navigate('/charts');
        }
    }, [current]);
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    if (!(userData && (!userData.isAnonymous))) {
        return (<Flex style={{width: '100vw'}} gap={"large"}>
            <Text style={{fontSize: '28pt', color: '#daffda'}}>Grass.su</Text>
            <Menu onClick={onClick}
                  mode="horizontal"
                  theme="dark"
                  style={{
                      flex: 1,
                      minWidth: 0,
                  }}
            />
        </Flex>);
    } else {
        return (
            <Flex style={{width: '100vw'}} justify={"space-between"} align={"center"}>
                <Flex gap={"large"}>
                    <Text style={{fontSize: '28pt', color: '#daffda'}}>Grass.su</Text>
                    <Menu onClick={onClick} selectedKeys={[(current === "login" ? null : current)]}
                          mode="horizontal"
                          theme="dark"
                          items={items}
                          style={{
                              flex: 1,
                              minWidth: 0,
                              width: "68vw"
                          }}
                    />
                </Flex>
                <Button type={"link"} style={{
                    color: "#b6dab9"
                }} href={apiURL + "/logout"}>Выйти</Button>
            </Flex>
        );
    }
}


export default HeaderContent;