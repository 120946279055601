import {useEffect, useState} from "react";
import axios from "axios";
import {apiURL} from "../config";


export const useApi = (url, request_data = {}, deps = []) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    useEffect(() => {
        setIsLoading(true);

        axios
            .get(apiURL + url, {params: request_data})
            .then((res) => {
                console.log(res.data);
                setData(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setIsLoading(false);
            });
    }, deps);

    return [data, isLoading, error];
}

export const useUser = () => useApi("/user", {});
export const useGoods = (deps = [], withDiff = false, outOfStock = false, page = 1, step = 10) => useApi("/goods", {
    withDiff,
    outOfStock,
    page,
    step
}, deps);
export const useCharts = (deps = [], goodId = null, categoryName = null) => useApi("/charts", {
    goodId,
    categoryName
}, deps);
export const useUsers = (deps = []) => useApi("/admin/users", {}, deps);
export const useSettings = (deps = []) => useApi("/admin/settings", {}, deps);

export const postApi = (url, data, setData = ((e) => {
                        }), setError = ((e) => {
                        }),
                        reload = false) => {
    axios
        .post(apiURL + url, data)
        .then((res) => {
            console.log(res.data);
            setData(res.data);

            return res.data;
        })
        .catch(err => {
            console.log(err.message);
            setError(err.message);

            return err.message;
        });

}
export const postLogin = (login, password, setData, setError) => postApi(
    "/login",
    {login: login, password: password},
    setData,
    setError
);

export const postCreateUser = (login, name, password, setData) => postApi(
    "/admin/user/create",
    {login: login, name: name, password: password},
    setData
);

export const postCreateGood = (data, setData) => postApi(
    "/goods/create",
    data,
    setData
);

export const postCreateGoodCompetitor = (data, setData) => postApi(
    "/goods/competitor/create",
    data,
    setData
);

export const postDeleteUsers = (usernames, setData) => postApi(
    "/admin/users/delete",
    {usernames: usernames},
    setData
);

export const postDeleteGoods = (goodIds, setData) => postApi(
    "/goods/delete",
    {goodIds: goodIds},
    setData,
    setData
);

export const postUpdatePrices = (ids, setData) => postApi(
    "/goods/update/price",
    {goodsIds: ids},
    setData,
    setData
);

export const postSettings = (settings, setData) => postApi(
    "/admin/settings",
    settings,
    setData,
    setData
);

export const postAiQuestion = (question, setData) => postApi("/chat/ai_question", {"question": question}, setData, setData);