import Login from "../../components/login/login";
import {Flex, Typography} from "antd";

const {Title} = Typography;

const LoginPage = () => {

    return (
        <Flex vertical align={"center"} justify={"center"} style={{
            height: '80vh',
            width: '100%',
        }} gap={"large"}>
            <Title level={1}>Авторизация</Title>
            <Login/>
        </Flex>
    );
}

export default LoginPage;