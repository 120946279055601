import {Button, Card, Flex, Modal, Space, Statistic, Table, Typography, DatePicker, Select, Input} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, InboxOutlined} from "@ant-design/icons";
import {LineChart} from "@mui/x-charts";
import {useContext, useEffect} from "react";
import {MessageContext} from "../../pages/App/App";
import {apiURL} from "../../config";
import Dragger from "antd/es/upload/Dragger";

const {Title, Text} = Typography;
const {RangePicker} = DatePicker;

const ImportGoodsModal = (props) => {
    const {isModalOpen, setIsModalOpen, responseData, setResponseData} = props;
    const {messageApi} = useContext(MessageContext);

    useEffect(() => {
        if (responseData && responseData.status === "success") {
            messageApi.success(responseData.message);
        } else if (responseData && responseData.status === "error") {
            messageApi.error(responseData.message);
        }
        setResponseData(null);
    }, [messageApi, responseData, setResponseData, isModalOpen, setIsModalOpen]);


    const upload_props = {
        name: 'file',
        multiple: true,
        action: apiURL + "/import",
        onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                messageApi.success(`Данные успешно импортированы.`);
            } else if (status === 'error') {
                messageApi.error(`Ошибка при импортировании данных из файла ${info.file.name}.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Modal title={"Импортировать товары"}
               open={isModalOpen} onCancel={() => setIsModalOpen(false)}
               onOk={() => setIsModalOpen(false)}
            // width={"80%"}
               cancelButtonProps={{style: {display: 'none'}}}
               okButtonProps={{style: {display: 'none'}}}
               okText={"OK"}
        >
            <Flex vertical gap={"middle"}>
                <Dragger {...upload_props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Нажмите или перенесите сюда файл с таблицей</p>
                    <p className="ant-upload-hint">
                        .xlsx, .xls
                    </p>
                </Dragger>

            </Flex>
        </Modal>

    );
}

export default ImportGoodsModal;